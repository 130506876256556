
$(document).ready(function () {
   //language datepicker
   let local = document.head.querySelector('meta[name="lang"]');
   moment.locale(local.content)
   //timezone
  $('#tz').val(moment.tz.guess())

});

$(function(){

  $('#resetPassword').click(function () {
      $('#resetPass').hide();
      $('#login').show();
  });

  $('#showLogin').click(function () {
      $('#reset').hide();
      $('#login').show();
  });

  $('#showLoginValidation').click(function () {
      $('#validation').hide();
      $('#login').show();
  });

});
